import { template as template_987ffd04739345b88d31d9f8a28a672b } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_987ffd04739345b88d31d9f8a28a672b(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
