import { template as template_442bc9fbecec4286b9ca8c34cde5688d } from "@ember/template-compiler";
const WelcomeHeader = template_442bc9fbecec4286b9ca8c34cde5688d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
