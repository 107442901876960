import { template as template_6e7773346bff489cb6c918c24e8ea9af } from "@ember/template-compiler";
const SidebarSectionMessage = template_6e7773346bff489cb6c918c24e8ea9af(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
